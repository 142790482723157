var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "v-container",
        { staticClass: "payment-completed" },
        [
          _c(
            "div",
            { staticClass: "checkout-title" },
            [
              _c("CategoryTitle", {
                attrs: {
                  category: _vm.category,
                  showOnMobile: true,
                  centered: _vm.$vuetify.breakpoint.xs,
                  showDescription: false
                }
              })
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-7", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "h-100",
                      attrs: { color: "transparent", elevation: "0" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center flex-row flex-wrap justify-center justify-sm-start"
                        },
                        [
                          _vm.order && _vm.paymentFailed
                            ? _c(
                                "h1",
                                {
                                  staticClass: "font-weight-bold primary--text"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("paymentCompleted.failed")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm.order && _vm.paymentFailed
                        ? _c("v-card-text", { staticClass: "px-0 mt-3" }, [
                            _c("div", {
                              staticClass:
                                "text-body-1 mb-6 text-center text-sm-left",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("paymentCompleted.failedDesc")
                                )
                              }
                            })
                          ])
                        : _vm._e(),
                      _vm.order && !_vm.paymentFailed
                        ? _c(
                            "v-card-text",
                            { staticClass: "text-center text-sm-left" },
                            [
                              _c("h1", {
                                staticClass:
                                  "font-weight-bold secondary--text mb-6",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t("paymentCompleted.success")
                                  )
                                }
                              }),
                              _vm.paymentPending
                                ? _c("i18n", {
                                    staticClass: "text-body-1 font-weight-bold",
                                    attrs: {
                                      path: "paymentCompleted.pending",
                                      tag: "div"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "link",
                                          fn: function() {
                                            return [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "text-decoration-none",
                                                  attrs: {
                                                    to: {
                                                      name: "Order",
                                                      params: {
                                                        orderId:
                                                          _vm.order.orderId
                                                      }
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "paymentCompleted.ordersLink"
                                                            )
                                                          )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      1083077343
                                    )
                                  })
                                : _vm._e(),
                              _c(
                                "v-container",
                                { staticClass: "text-body-1 px-0 mt-6" },
                                [
                                  !_vm.order.isPayable &&
                                  !_vm.order.homeDelivery &&
                                  !_vm.easyDrive
                                    ? _c("div", [
                                        _c("ul", [
                                          _c("li", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t(
                                                  "paymentCompleted.detail.pickup.note",
                                                  [_vm.order.orderId]
                                                )
                                              )
                                            }
                                          }),
                                          _c("li", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t(
                                                  "paymentCompleted.detail.pickup.reminder"
                                                )
                                              )
                                            }
                                          }),
                                          _c("li", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t(
                                                  "paymentCompleted.detail.pickup.where",
                                                  [
                                                    _vm.order.shippingAddress
                                                      .addressName
                                                  ]
                                                )
                                              )
                                            }
                                          })
                                        ])
                                      ])
                                    : _vm._e(),
                                  !_vm.order.isPayable && _vm.order.homeDelivery
                                    ? _c("div", [
                                        _c("ul", [
                                          _c("li", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t(
                                                  "paymentCompleted.detail.home.note",
                                                  [_vm.order.orderId]
                                                )
                                              )
                                            }
                                          }),
                                          _c("li", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t(
                                                  "paymentCompleted.detail.home.reminder"
                                                )
                                              )
                                            }
                                          }),
                                          _c("li", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t(
                                                  "paymentCompleted.detail.home.where",
                                                  [
                                                    _vm.order.shippingAddress
                                                      .address1,
                                                    _vm.order.shippingAddress
                                                      .city
                                                  ]
                                                )
                                              )
                                            }
                                          })
                                        ])
                                      ])
                                    : _vm._e()
                                ]
                              ),
                              _c("v-divider", { staticClass: "my-5" }),
                              _vm.order.orderStatusId == 2
                                ? _c("p", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("order.info1", [
                                          _vm.order.orderId
                                        ])
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm.order.orderStatusId == 1 &&
                              _vm.order.paymentTypeId == 1
                                ? _c("p", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("order.info2", [
                                          _vm.order.orderId
                                        ])
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm.order.orderStatusId == 11
                                ? _c("p", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("order.info3", [
                                          _vm.order.orderId
                                        ])
                                      )
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.order
                        ? _c("v-card-text", [
                            _c("h1", {
                              staticClass:
                                "font-weight-bold default--text mb-4",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("paymentCompleted.successNoOrder")
                                )
                              }
                            })
                          ])
                        : _vm._e(),
                      _c(
                        "v-card-actions",
                        {},
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                justify: "center",
                                align: "center"
                              }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "px-0 px-sm-3 mb-4 mb-sm-0",
                                  attrs: { cols: "12", sm: "auto" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "secondary--text",
                                      attrs: {
                                        "x-large": "",
                                        depressed: "",
                                        outlined: "",
                                        block: _vm.$vuetify.breakpoint.xs
                                      },
                                      on: { click: _vm.goHome }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("paymentCompleted.goToHome")
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm.paymentFailed && _vm.order.isPayable
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "mb-4 mb-sm-0",
                                      attrs: { cols: "12", sm: "auto" }
                                    },
                                    [
                                      !_vm.showPayment
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                "x-large": "",
                                                depressed: "",
                                                color: "primary",
                                                block:
                                                  _vm.$vuetify.breakpoint.xs
                                              },
                                              on: {
                                                click: _vm.toggleShowPayment
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "paymentCompleted.retry"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                {
                                  staticClass: "px-0 px-sm-3",
                                  attrs: { cols: "12", sm: "auto" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        to:
                                          "/profile/orders/" +
                                          _vm.order.orderId,
                                        "x-large": "",
                                        depressed: "",
                                        color: "primary",
                                        block: _vm.$vuetify.breakpoint.xs
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "paymentCompleted.goToDetail"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.showPayment
                        ? _c("PaymentTypeList", {
                            attrs: {
                              mode: "order",
                              "order-id": _vm.order.orderId,
                              options: _vm.paymentTypeListOptions
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }